import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  BehaviorSubject,
  Observable,
  tap,
  catchError,
  of,
  take,
  switchMap,
  map,
  first,
} from 'rxjs';

import { environment } from 'environments/environment';
import { cloneDeep } from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class CampanhaService {
  private _campanhas: BehaviorSubject<any> = new BehaviorSubject([]);
  private _cadeias: BehaviorSubject<any> = new BehaviorSubject(null);
  private _mensagemAprovada: BehaviorSubject<any> = new BehaviorSubject([]);

  /**
   * Constructor
   */
  constructor(private _httpClient: HttpClient) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Getters
   */
  get campanhas$(): Observable<any> {
    return this._campanhas.asObservable();
  }

  get mensagemAprovada$(): Observable<any> {
    return this._mensagemAprovada.asObservable();
  }

  get cadeias$(): Observable<any> {
    return this._cadeias.asObservable();
  }

  getCadeias(): Observable<any> {
    return this._httpClient
      .get<any>(`${environment.api}/assistenciaTecnica/cadeias`)
      .pipe(
        tap((response: any[]) => {
          this._cadeias.next(response);
        })
      );
  }

  salvarNovaPessoa(pessoa: any) {
    return this._httpClient
      .post(`${environment.api}/pessoa/salvarNovaPessoa`, pessoa)
      .pipe(take(1), first());
  }

  getAnexo(id: any): Observable<any> {
    return this._httpClient
      .get<any>(`${environment.api}/docAnexo/getById/${id}`)
      .pipe(tap((response: any[]) => response));
  }

  getCampanha(): Observable<any> {
    console.log('get Campanha =====');
    return this._httpClient.get(`${environment.api}/campanha/all`).pipe(
      catchError((error) => of(this._campanhas.next([]))),
      tap((response: any) => {
        this._campanhas.next(response);

        return response;
      })
    );
  }

  getAll(filtro): Observable<any> {
    console.log('get Campanha =====');
    return this._httpClient
      .post(`${environment.api}/campanha/all`, {
        ...filtro,
        grupoFiliacao: filtro.grupoFiliacao ? filtro.grupoFiliacao : null,
        autor: filtro.autor ? filtro.autor.login : null,
        status: !filtro.status || filtro.status == 'Todos' ? null : filtro.status,
        tipo: !filtro.tipo || filtro.tipo == 'Todos' ? null : filtro.tipo,
        numero: !filtro.numero || filtro.tipo == 'Todos' ? null : filtro.tipo,
      })
      .pipe(
        catchError((error) => of(this._campanhas.next([]))),
        tap((response: any) => {
          this._campanhas.next(response);

          return response;
        })
      );
  }

  createPergunta(body: any): Observable<any> {
    return this._httpClient
      .post(`${environment.api}/campanha/pergunta`, body)
      .pipe(
        catchError((error) => of(true)),
        tap((response: any) => response)
      );
  }

  createForm(body: any): Observable<any> {
    return this._httpClient
      .post(`${environment.api}/campanha/pergunta/padrao`, body)
      .pipe(
        catchError((error) => of(true)),
        tap((response: any) => response)
      );
  }

  saveAllForms(body: any): Observable<any> {
    return this._httpClient
      .post(`${environment.api}/campanha/pergunta/padrao/all`, body)
      .pipe(
        catchError((error) => of(true)),
        tap((response: any) => response)
      );
  }

  setAgendamento(id: number, date: string): Observable<any> {
    return this._httpClient
      .post(`${environment.api}/campanha/setAgendamento/${id}/${date}`, {})
      .pipe(take(1), first());
  }

  setSemInteresse(id: number): Observable<any> {
    return this._httpClient
      .post(`${environment.api}/campanha/setSemInteresse/${id}`, {})
      .pipe(take(1), first());
  }

  deletePergunta(id: any): Observable<any> {
    return this._httpClient
      .delete(`${environment.api}/campanha/pergunta/${id}`)
      .pipe(
        catchError((error) => of(true)),
        tap((response: any) => response)
      );
  }

  anexCampanha(removeCampanha: any, campanha: number, filtro: any) {
    return this._httpClient
      .post(`${environment.api}/campanha/anexarCampanha/${campanha}`, {
        filtro: filtro,
        idsRemove: removeCampanha,
      })
      .pipe(
        catchError((error) => {
          console.log('🚀  error:', error);
          return of(false);
        }),
        tap((response: any) => of(response))
      );
  }

  disparoWpp(campanha: number, holders: string[]) {
    return this._httpClient
      .post(`${environment.api}/campanha/disparo_whatsapp/${campanha}`, holders)
      .pipe(take(1), first());
  }

  countCampanha(campanha: Number) {
    return this._httpClient
      .get(`${environment.api}/campanha/campanhaByIdcount/${campanha}`)
      .pipe(
        catchError((error) => {
          console.log('🚀  error:', error);
          return of([]);
        }),
        tap((response: any) => of(response))
      );
  }

  getFormsPadroes() {
    return this._httpClient
      .get(`${environment.api}/campanha/perguntas/padroes`)
      .pipe(
        catchError((error) => {
          console.log('🚀  error:', error);
          return of([]);
        }),
        tap((response: any) => of(response))
      );
  }

  getTelefonesOrigem() {
    return this._httpClient.get(`${environment.api}/whatsapp`).pipe(
      catchError((error) => {
        console.log('🚀  error:', error);
        return of([]);
      }),
      tap((response: any) => of(response))
    );
  }

  save(campanha: any): Observable<any> {
    return this.campanhas$.pipe(
      take(1),
      switchMap((campan) =>
        this._httpClient.post(`${environment.api}/campanha`, campanha).pipe(
          map((updateCampanha: any) => {
            if (updateCampanha) {
              const index = campan.findIndex(
                (item: any) => item?.id === campanha?.id
              );

              if (index !== -1) {
                campan[index] = updateCampanha;
                campan.sort((a, b) => b.id - a.id);
                this._campanhas.next(campan);
              } else if (index === -1) {
                const att = cloneDeep(campan);
                att?.push(updateCampanha);
                att.sort((a, b) => b.id - a.id);
                this._campanhas.next(att);
              }

              return updateCampanha;
            } else {
              throw new Error();
            }
          })
        )
      )
    );
  }

  delete(id) {
    return this.campanhas$.pipe(
      take(1),
      switchMap((campanhas) =>
        this._httpClient.delete(`${environment.api}/campanha/${id}`).pipe(
          map((isDeleted: boolean) => {
            const index = campanhas.findIndex((item) => item.id === id);

            campanhas.splice(index, 1);

            this._campanhas.next(campanhas);

            return isDeleted;
          })
        )
      )
    );
  }

  listarCampanhasPorMensagemId(mensagemId): Observable<any> {
    return this._httpClient.get(
      `${environment.api}/campanha/listarCampanhasPorMensagemId/${mensagemId}`
    );
  }

  buscarCampanhaPadrao(numero): Observable<any> {
    return this._httpClient.get(
      `${environment.api}/whatsapp/getCampanhaPadraoByWppId/${numero}`
    );
  }

  ordenarCampanhaResposta(formulario): Observable<any> {
    return this._httpClient.post(
      `${environment.api}/campanha/ordenar`,
      formulario
    );
  }
}
