import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CpfCnpjPipe } from './cpfCnpj/cpf-cnpj.pipe';
import { PhonePipe } from './phone-pipe/phone.pipe';
import { SafeHtmlPipe } from './safeHtml/safeHtml.pipe';
import { SafePipe } from './safe/safe.pipe';
import { RemoveUnderscorePipe } from './remove-underscore/remove-underscore.pipe';
import { TruncatePipe } from './truncate/truncate.pipe';
import { TimeIntervalPipe } from './time-interval/time-interval.pipe';

@NgModule({
  declarations: [CpfCnpjPipe, PhonePipe, SafeHtmlPipe, SafePipe, RemoveUnderscorePipe, TruncatePipe, TimeIntervalPipe],
  imports: [CommonModule],
  exports: [CpfCnpjPipe, PhonePipe, SafeHtmlPipe, SafePipe, RemoveUnderscorePipe, TruncatePipe, TimeIntervalPipe],
})
export class PipeModule {}
